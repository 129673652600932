import { IPaginatedResponse } from 'common/types/api.types'
import { ILazyParams } from 'common/types/table.types'
import { useUser } from 'services/auth/hooks/useUser'
import { IFilter } from 'services/filters/filters.types'
import { deleteAPIKey, generateAPIKey, getAPIKey, sendApplicationUpdate, sendJobOffer } from 'services/jobs/jobs.api'
import { ApiPathsJobs } from 'services/jobs/jobs.service'
import { SWRConfiguration, default as useSWR } from 'swr'
import useSWRImmutable from 'swr/immutable'
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation'
import {
  ApiPathsCompany,
  addAccounts,
  addCompanyBenefit,
  changeStatusDeleteAccount,
  deleteAccount,
  deleteCompanyBenefit,
  getAccounts,
  getCompanies,
  getCompany,
  getCompanyBenefits,
  getDeleteRequest,
  getProfileCompletness,
  getUnvalidatedCompanies,
  initiateDeleteAccount,
  patchCompanyBenefit,
  updateAccount,
  updateCompany,
  validateCompany,
} from './company.api'
import {
  ICompanyProfile,
  ICompanyProfileAPIResponse,
  ICompanyVerification,
  IParsedCompanyProfile,
} from './company.types'
import { ICompanyProfileBenefits } from './components/Profile/components/CompanyProfile.types'
import { IDeleteAccountData } from './components/delete/delete.state'

export const parseCompanyApiResponse = (company: ICompanyProfileAPIResponse): IParsedCompanyProfile => {
  const parsedCompany = {
    ...company,
    jobCategories: company?.jobCategories?.map((job: IFilter) => job.id),
    locations: company?.locations?.map((location: IFilter) => location.id),
    description: company?.description ? { value: company.description as string, length: 1 } : { value: '', length: 1 },
    mission_statement: company?.mission_statement
      ? { value: company.mission_statement as string, length: 1 }
      : { value: '', length: 1 },
  }

  return parsedCompany
}

export const useGetCompany = (config: SWRConfiguration<any> = {}) => {
  const { data: user } = useUser()

  const id = user?.recruiter?.company?.id
  const { data, error, isLoading } = useSWRImmutable(id ? ApiPathsCompany.company(id) : null, getCompany, { ...config })

  const company = parseCompanyApiResponse(data)

  return {
    company,
    error,
    isLoading,
  }
}

export const useUpdateCompany = (
  config: SWRMutationConfiguration<any, any, ICompanyProfile | ICompanyVerification | FormData, string> = {},
) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsCompany.companies(), updateCompany, { ...config })

  return { trigger, isMutating }
}

export const useInitiateDeleteAccountRequest = (
  config: SWRMutationConfiguration<IDeleteAccountData, any, IDeleteAccountData, any> = {},
) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsCompany.deleteAccountRequest, initiateDeleteAccount, {
    ...config,
  })

  return { trigger, isMutating }
}

export const useCompanyAccounts = (config: SWRConfiguration<any> = {}) => {
  const { data, error, isLoading } = useSWRImmutable(ApiPathsCompany.accounts, getAccounts, { ...config })

  return {
    data,
    error,
    isLoading,
  }
}

export const useAddAccount = (config: SWRMutationConfiguration<any, any, any, string> = {}) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsCompany.accounts, addAccounts, { ...config })

  return { trigger, isMutating }
}

export const useUpdateAccount = (accountID: string, config: SWRMutationConfiguration<any, any, any, string> = {}) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsCompany.account(accountID), updateAccount, { ...config })

  return { trigger, isMutating }
}

export const useInitiateDeleteAccountRequestBenefit = (
  config: SWRMutationConfiguration<ICompanyProfileBenefits, any, string | undefined, string> = {},
) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsCompany.benefits, deleteCompanyBenefit, { ...config })

  return { trigger, isMutating }
}

export const useDeleteAccount = (accountID: string, config: SWRMutationConfiguration<any, any, void, string> = {}) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsCompany.account(accountID), deleteAccount, { ...config })

  return { trigger, isMutating }
}

// COMPANY BENEFITS ->

export const useGetCompanyBenefits = (config: SWRConfiguration<any> = {}) => {
  const { data, error, isLoading, mutate } = useSWR(ApiPathsCompany.benefits, getCompanyBenefits, { ...config })

  return {
    data,
    error,
    isLoading,
    mutate,
  }
}

export const useAddCompanyBenefit = (config: any) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsCompany.benefits, addCompanyBenefit, { ...config })

  return { trigger, isMutating }
}

export const usePatchCompanyBenefit = (config: any) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsCompany.benefits, patchCompanyBenefit, {
    ...config,
  })

  return { trigger, isMutating }
}

export const useSendJobOffer = (config: any) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsJobs.jobOffer(''), sendJobOffer, { ...config })

  return { trigger, isMutating }
}

export const useApplicationStatusUpdate = (config: any) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsJobs.applicationStatus(''), sendApplicationUpdate, {
    ...config,
  })

  return { trigger, isMutating }
}

export const useDeleteAccountValidator = (id: string, config: any) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsCompany.deleteAccountAPI(id), deleteAccount, {
    ...config,
  })

  return { trigger, isMutating }
}

export const useChangeStatusValidator = (id: string, config: any) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsCompany.deleteAccountAPI(id), changeStatusDeleteAccount, {
    ...config,
  })

  return { trigger, isMutating }
}

export const useCompanyProfileCompletness = (config: SWRConfiguration<any> = {}) => {
  const { data, error, isLoading } = useSWR(ApiPathsCompany.profileCompletness(), getProfileCompletness, {
    ...config,
  })

  return { data, error, isLoading }
}

export const useGetUnvalidatedCompanies = (filters?: ILazyParams, config: any = {}) => {
  let filtersToApply = ''
  if (filters) {
    filtersToApply = `?page=${filters.page}&limit=${filters.rows}&filter.status=$in:0`

    if (filters.sortOrder) {
      filtersToApply += `&sortBy=${filters.sortOrder}:ASC`
    }
  }

  const { data, error, mutate } = useSWR(ApiPathsCompany.companies(filtersToApply), getUnvalidatedCompanies, {
    ...config,
  })

  return {
    data: data?.data as any,
    meta: data?.meta,
    error,
    mutate,
    isLoading: !data && !error,
  }
}

const sortKeyToRequest = {
  'user.email': 'email',
  createdAt: 'createdAt',
  adminEmail: 'email',
  adminFirstName: 'firstName',
  adminLastName: 'lastName',
  phone: 'phone',
  jobs: 'jobs',
  name: 'name',
}

export const useGetCompanies = (
  filters?: ILazyParams,
  shouldFetch: boolean = true,
  config: SWRConfiguration<IPaginatedResponse<ICompanyProfileAPIResponse>> = {},
) => {
  let filtersToApply = ''
  if (filters) {
    filtersToApply = `?page=${filters.page + 1}&limit=${filters.rows || 20}`

    if (filters.sortField) {
      filtersToApply += `&sortBy=${sortKeyToRequest?.[filters.sortField as keyof typeof sortKeyToRequest]}:${
        filters.sortOrder === 1 ? 'ASC' : 'DESC'
      }`
    }

    if (filters.search) {
      filtersToApply += `&search=${filters.search}`
    }
  }
  //@ts-ignore
  const { data, error, mutate } = useSWR(shouldFetch ? ApiPathsCompany.companies(filtersToApply) : null, getCompanies, {
    ...config,
  })

  return {
    data: data?.data,
    meta: data?.meta,
    error,
    mutate,
    isLoading: !data && !error,
  }
}

export const useDeleteAccountRequests = (
  filters?: ILazyParams,
  config: SWRConfiguration<IPaginatedResponse<ICompanyProfileAPIResponse>> = {},
) => {
  let filtersToApply = ''
  if (filters) {
    filtersToApply = `?page=${filters.page + 1}&limit=${filters.rows || 20}`

    if (filters.sortField !== '') {
      filtersToApply += `&sortBy=${filters.sortField}`
    }

    if (filters.sortOrder) {
      filtersToApply += `:${filters.sortOrder === 1 ? 'ASC' : 'DESC'}`
    }

    if (filters.search) {
      filtersToApply += `&search=${filters.search}`
    }
  }
  //@ts-ignore
  const { data, error, mutate } = useSWR(ApiPathsCompany.deleteAccountRequestAPI(filtersToApply), getDeleteRequest, {
    ...config,
  })

  return {
    data: data?.data,
    meta: data?.meta,
    error,
    mutate,
    isLoading: !data && !error,
  }
}

export const useValidateCompany = (config: SWRMutationConfiguration<any, any, any, string> = {}) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsCompany.companyValidation, validateCompany, {
    ...config,
  })

  return { trigger, isMutating }
}

export const useGetAPIKey = (config: any) => {
  const { data, isLoading, error, mutate } = useSWRImmutable(ApiPathsJobs.currentAPIKey, getAPIKey, { ...config })

  return { data, isLoading, error, mutate }
}

export const useGenerateAPIKey = (config: any) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsJobs.generateAPIKey, generateAPIKey, { ...config })

  return { trigger, isMutating }
}

export const useDeleteAPIKey = (config: any) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsJobs.deleteAPIKey, deleteAPIKey, { ...config })

  return { trigger, isMutating }
}

export const authService = { getCompany, updateAccount, deleteAccount, useDeleteAccount, useUpdateAccount }
