import cx from 'classnames'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
export interface NavLinkProps extends LinkProps {
  children: React.ReactNode | React.ReactNode[]
  className?: string
  disabled?: boolean
}

export function NavLink({ children, href, disabled = false, className, ...props }: NavLinkProps) {
  const router = useRouter()

  return (
    <Link href={href} className={cx(className, { active: router.pathname === href, disabled })} {...props}>
      {children}
    </Link>
  )
}
