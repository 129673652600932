import { useGet, useGetPublic } from 'common/hooks/useApi'
import { ApiPathsSettings } from 'services/account/account.service'
import { IUser } from '../auth.types'

export const useUser = () => {
  const { data, error, isLoading, mutate } = useGet<IUser>(ApiPathsSettings.currentUser)
  return {
    data,
    error,
    isLoading,
    mutate,
  }
}

export const useUserPublic = () => {
  const { data, error, isLoading, mutate } = useGetPublic<IUser>(ApiPathsSettings.currentUser)
  return {
    data,
    error,
    isLoading,
    mutate,
  }
}
