import { Dialog, DialogProps } from 'primereact/dialog'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

interface IConfirmationModal extends Omit<DialogProps, 'onHide'> {
  title?: string | JSX.Element
  onHide?: () => void
  defaultVisible?: boolean
}

export const BasicModal: FC<IConfirmationModal> = ({ title, onHide, children, defaultVisible = false, ...props }) => {
  const [visible, setVisible] = useState(defaultVisible)

  const onCancel = () => {
    onHide && onHide()
    setVisible(false)
  }

  useEffect(() => {
    setVisible(defaultVisible)
  }, [defaultVisible])

  return (
    <StyledDialog
      style={{ width: '96vw', maxWidth: '600px' }}
      visible={visible}
      onHide={onCancel}
      blockScroll
      {...props}
    >
      <div>
        {title && <div className="modal-title">{title}</div>}
        {children}
      </div>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)`
  text-align: center;

  .p-dialog-content {
    display: flex;
    justify-content: center;
  }

  .modal-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 1rem;
  }
`
