import styled, { keyframes } from 'styled-components'
import LoadingAnimationIcon from './UnionLoadingIcon.svg'

interface IProps extends React.SVGProps<SVGSVGElement> {
  size?: number
}

export const LoadingAnimation = ({ size = 16, ...props }: IProps) => {
  return <StyledLoadingAnimation size={size} {...props} />
}

const animateStroke1 = keyframes`
  0% {
    stroke-dashoffset: 8.316773891448975px;
    stroke-dasharray: 8.316773891448975px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 8.316773891448975px;
  }
`

const animateFill1 = keyframes`
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 223, 53);
  }
  
`

const animateStroke2 = keyframes`
  0% {
    stroke-dashoffset: 180.47360229492188px;
    stroke-dasharray: 180.47360229492188px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 180.47360229492188px;
  }
`

const animateFill2 = keyframes`
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 223, 53);
  }
`

const animateStroke3 = keyframes`
  0% {
    stroke-dashoffset: 38.719032287597656px;
    stroke-dasharray: 38.719032287597656px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 38.719032287597656px;
  }
`

const animateFill3 = keyframes`
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 223, 53);
  }
`

const animateStroke4 = keyframes`
  0% {
    stroke-dashoffset: 38.71900177001953px;
    stroke-dasharray: 38.71900177001953px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 38.71900177001953px;
  }
`

const animateFill4 = keyframes`
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 223, 53);
  }
`

const animateStroke5 = keyframes`
  0% {
    stroke-dashoffset: 543.0308227539062px;
    stroke-dasharray: 543.0308227539062px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 543.0308227539062px;
  }
`

const animateFill5 = keyframes`
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 223, 53);
  }
`

const StyledLoadingAnimation = styled(LoadingAnimationIcon)<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  transform: scale(${({ scale }) => scale});
  .svg-elem-1 {
    -webkit-animation: ${animateStroke1} 1s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 0s both,
      ${animateFill1} 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 0.8s both;
    animation: ${animateStroke1} 1s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 0s both,
      ${animateFill1} 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 0.8s both;
  }

  .svg-elem-2 {
    -webkit-animation: ${animateStroke2} 1s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 0.12s both,
      ${animateFill2} 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 0.9s both;
    animation: ${animateStroke2} 1s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 0.12s both,
      ${animateFill2} 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 0.9s both;
  }

  .svg-elem-3 {
    -webkit-animation: ${animateStroke3} 1s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 0.24s both,
      ${animateFill3} 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 1s both;
    animation: ${animateStroke3} 1s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 0.24s both,
      ${animateFill3} 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 1s both;
  }

  .svg-elem-4 {
    -webkit-animation: ${animateStroke4} 1s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 0.36s both,
      ${animateFill4} 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 1.1s both;
    animation: ${animateStroke4} 1s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 0.36s both,
      ${animateFill4} 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 1.1s both;
  }

  .svg-elem-5 {
    -webkit-animation: ${animateStroke5} 1s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 0.48s both,
      ${animateFill5} 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 1.2000000000000002s both;
    animation: ${animateStroke5} 1s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 0.48s both,
      ${animateFill5} 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) infinite alternate 1.2000000000000002s both;
  }
`
