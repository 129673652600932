import { ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalColorClasses } from './GlobalColorClasses.styled'
import { GlobalStyles } from './GlobalStyles.styled'
import { PrimereactThemeResets } from './PrimereactThemeResets.styled'

export const theme = {
  colors: {
    primary_100: '#FFDF35',
    primary_85: '#FFE453',
    primary_70: '#FFE871',
    primary_55: '#FFEE90',
    primary_40: '#FFF2AE',
    primary_25: '#FFF7CC',
    primary_10: '#FFFCEB',
    secondary_100: '#96AFC5',
    secondary_85: '#A6BBCD',
    secondary_80: '#A5ACB8',
    secondary_70: '#B5C7D6',
    secondary_55: '#C5D3DF',
    secondary_40: '#D5DFE8',
    secondary_25: '#E5EBF0',
    secondary_10: '#F5F7FA',
    secondary_blue_dark: '#527696',
    secondary_blue: '#527696',
    secondary_blue_light: '#869FB6',
    fullBlack: '#000000',
    chinese_black: '##151215',
    black: '#242424',
    white: '#ffffff',
    grey_100: '#333333',
    gray_85: '#4F4F4F',
    gray_70: '#828282',
    gray_55: '#BDBDBD',
    gray_40: '#E0E0E0',
    gray_25: '#ECEDEE',
    gray_10: '#F2F2F2',
    gray_HTML: '#7E7F87',
    cadetBlue: '#B3AECB',
    error: '#F47065',
    bg_error: 'rgba(244, 112, 101, 0.30)',
    warning: '#FFC046',
    bg_warning: 'rgba(255, 192, 70, 0.3)',
    success: '#8DDF9B',
    bg_success: 'rgba(52, 190, 76, 0.30)',
  },

  constants: {
    navbarHeight: '70.47px',
    sideBarWidth: '212px',
    //   pageTitleHeight: themeConstant(68),
    //   tableHeadHeight: themeConstant(56),
    //   paginationHeight: themeConstant(44),
    //   breadcrumbHeight: themeConstant(62),
  },
}

interface IProps {
  children: ReactNode | ReactNode[]
}

export const Theme = ({ children }: IProps) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <PrimereactThemeResets />
      <GlobalColorClasses />
      {children}
    </ThemeProvider>
  )
}
