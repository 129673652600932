import cx from 'classnames'
import { breadcrumbIdAtom } from 'common/state/breadcrumbs.state'
import { useAtom } from 'jotai'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const convertBreadcrumb = (path: string) => {
  return path?.replace(/-/g, ' ').replace(/oe/g, 'ö').replace(/ae/g, 'ä').replace(/ue/g, 'ü').toUpperCase()
}

export const Breadcrumbs = () => {
  const router = useRouter()
  const [breadcrumbs, setBreadcrumbs] = useState<null | { breadcrumb: string; href: string }[]>(null)
  const [breadcrumbId] = useAtom(breadcrumbIdAtom)

  useEffect(() => {
    if (router) {
      const ogPath = router.asPath.split('/')
      ogPath.shift()
      const linkPath = ogPath.slice(0, 1)
      if (ogPath.length > 1) {
        linkPath.push(breadcrumbId)
      }

      const pathArray = linkPath.map((path, i) => {
        return { breadcrumb: path, href: '/' + linkPath.slice(0, i + 1).join('/') }
      })

      setBreadcrumbs(pathArray)
    }
  }, [breadcrumbId, router])

  if (!breadcrumbs) {
    return null
  }

  return (
    <nav aria-label="breadcrumbs" className="flex align-items-center gap-2">
      {breadcrumbs.map((breadcrumb, i) => {
        const isLast = i === breadcrumbs.length - 1
        return (
          <span key={breadcrumb.href} className={cx({ 'opacity-40': !isLast })}>
            {'/ '}
            <>{convertBreadcrumb(breadcrumb.breadcrumb)}</>
          </span>
        )
      })}
    </nav>
  )
}
