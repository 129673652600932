import { IPaginatedResponse } from 'common/types/api.types'
import { http } from 'services/http'
import { IValidatorActiveAccounts } from 'services/jobs/jobs.types'
import { IDeleteAccountMultipleResponse } from 'services/validators/validators-type'
import { ICompanyProfile, ICompanyProfileAPIResponse, ICompanyVerification, IProfileCompletness } from './company.types'
import { ICoWorker, ICompanyProfileBenefits } from './components/Profile/components/CompanyProfile.types'
import { IDeleteAccountData } from './components/delete/delete.state'

export const ApiPathsCompany = {
  companies: (filters?: string) => `/companies${filters || ''}`,
  company: (id: string) => `/companies/${id}`,
  verification: (id: string) => `/companies/verification/${id}`,
  accounts: `/companies/recruiters`,
  account: (id: string) => `/companies/recruiters/${id}`,
  benefits: `/company/benefits`,
  profileCompletness: () => '/auth/profile-completness',
  deleteAccountRequestAPI: (filters?: string) => `/user-management/delete-requests${filters || ''}`,
  deleteAccountAPI: (id?: string) => `/user-management/delete-requests/${id}`,
  deleteAccountRequest: `/user-management/delete-requests`,
  myDeleteAccountRequest: `/user-management/delete-requests/me`,
  deleteAccountRequestCode: `/user-management/request-code`,
  deleteAccountVerifyCode: `/user-management/delete-account`,
  companyRecruiters: '/companies/recruiters',
  companyValidation: `/companies/validation`,
  companyFunnelBoard: (id: string) => `/companies/${id}/board`,
  companyFunnelBoardForJob: (companyId: string, jobId: string) => `/companies/${companyId}/board/${jobId}`,
  companyFunnelBoardColumn: (companyId: string, columnId: string) => `/companies/${companyId}/board/${columnId}`,
}

export const getCompany = async (url: string): Promise<ICompanyProfileAPIResponse> => {
  const { data } = await http.get(url)
  return data
}

export const updateCompany = async (
  url: string,
  { arg }: { arg: ICompanyProfile | ICompanyVerification | FormData },
) => {
  const { data } = await http.patch(url, arg)
  return data
}

export const initiateDeleteAccount = async (url: string, { arg }: { arg: IDeleteAccountData }) => {
  const { data } = await http.post(url, arg)
  return data
}

export const verifyCompany = async (url: string, { arg }: { arg: ICompanyVerification }): Promise<ICompanyProfile> => {
  const { data } = await http.patch(url, arg)
  return data
}

export const getAccounts = async (url: string): Promise<ICoWorker[]> => {
  const { data } = await http.get(url)
  return data
}

export const addAccounts = async (url: string, { arg }: { arg: { email: string } }) => {
  const { data } = await http.post(url, arg)
  return data
}

export const updateAccount = async (url: string, { arg }: { arg: { email: string } }) => {
  const { data } = await http.patch(url, arg)
  return data
}

export const changeStatusDeleteAccount = async (url: string, { arg }: { arg: { status: number } }): Promise<any> => {
  const { data } = await http.patch(url, arg)
  return data
}

export const deleteAccount = async (url: string) => {
  const { data } = await http.delete(url)
  return data
}

export const getCompanyBenefits = async (url: string): Promise<ICompanyProfileBenefits[]> => {
  const { data } = await http.get(url)

  return data
}

export const addCompanyBenefit = async (url: string, { arg }: any) => {
  const { data } = await http.post(url, arg)

  return data
}

export const patchCompanyBenefit = async (url: string, { arg }: any) => {
  const { data } = await http.patch(`${url}/${arg.id}`, arg)

  return data
}

export const deleteCompanyBenefit = async (url: string, { arg }: any) => {
  const { data } = await http.delete(`${url}/${arg}`)

  return data
}

export const getProfileCompletness = async (url: string): Promise<IProfileCompletness> => {
  const { data } = await http.get(url)

  return data
}

export const validateCompany = async (url: string, { arg }: { arg: { companyId: string; isApproved: boolean } }) => {
  const dataToSend = {
    isApproved: arg.isApproved,
    companyId: arg.companyId,
  }
  const { data } = await http.patch(url, dataToSend)
  return data
}

export const getUnvalidatedCompanies = async (
  url: string,
): Promise<IPaginatedResponse<ICompanyProfileAPIResponse[]>> => {
  const { data } = await http.get(url)
  return data
}

export const getCompanies = async (url: string): Promise<IPaginatedResponse<IValidatorActiveAccounts[]>> => {
  const { data } = await http.get(url)
  return data
}

export const getDeleteRequest = async (url: string): Promise<IDeleteAccountMultipleResponse> => {
  const { data } = await http.get(url)
  return data
}
