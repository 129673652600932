import PersonPlaceholder from 'assets/landing-page/personPlaceholder.png'
import { pagesPaths } from 'common/router/routes.utils'
import { nextImageLoaderProp } from 'common/utils/misc'
import moment from 'moment'
import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'
import { INotification } from 'services/notifications/notifications.type'
import styled from 'styled-components'
interface INotificationMessage {
  notification: INotification
  onHideSidebar: () => void
}

export const NotificationMessage: FC<INotificationMessage> = ({ notification, onHideSidebar }) => {
  const message = {
    0: (
      <div>
        <Link href={`${pagesPaths.headhunting}/${notification.data.candidateId}`} onClick={() => onHideSidebar()}>
          {notification.data.candidateName || 'Candidate'}
        </Link>{' '}
        <span>applied to your job: </span>
        <Link
          href={`/jobs/${notification.data.jobSlug ? notification.data.jobSlug : notification.data.jobId}`}
          onClick={() => onHideSidebar()}
        >
          {notification.data.jobName || 'Job'}{' '}
        </Link>
      </div>
    ),
    4: (
      <>
        <Link href={`${pagesPaths.headhunting}/${notification.data.candidateId}`} onClick={() => onHideSidebar()}>
          {notification.data.candidateName || 'Candidate'}
        </Link>{' '}
        <span>accepted your interview offer.</span>
      </>
    ),
    19: (
      <>
        <Link href={`${pagesPaths.headhunting}/${notification.data.candidateId}`} onClick={() => onHideSidebar()}>
          {notification.data.candidateName || 'Candidate'}
        </Link>{' '}
        <span>rejected your offer for job: </span>
        <Link
          href={`/jobs/${notification.data.jobSlug ? notification.data.jobSlug : notification.data.jobId}`}
          onClick={() => onHideSidebar()}
        >
          {notification.data.jobName || 'Job'}{' '}
        </Link>
        .
      </>
    ),
  }[notification.type]

  const displayNotificationDate = (date: string): string => {
    const localDate = moment(new Date(), 'yyyy-MM-dd')
    const notificationSignOnDate = moment(new Date(date), 'yyyy-MM-dd')
    const displayedDate = moment(new Date(date)).format('DD/MM/yyyy')
    const displayedTime = moment(new Date(date)).format('HH:mm')
    const diffDays = notificationSignOnDate.diff(localDate, 'days')

    return diffDays === 0
      ? `Today at ${displayedTime}`
      : diffDays === -1
      ? `Yesterday at ${displayedTime}`
      : `${displayedDate} at ${displayedTime}`
  }

  return (
    <StyledNotificationMessage>
      <Image
        width={32}
        height={32}
        src={notification.data.image ?? PersonPlaceholder}
        alt="Notification"
        loader={nextImageLoaderProp}
        unoptimized
      />
      <div>
        <div>{message}</div>
        <span className="notification-time">{displayNotificationDate(notification.createdAt)}</span>
      </div>
    </StyledNotificationMessage>
  )
}

const StyledNotificationMessage = styled.div`
  display: flex;
  gap: 1rem;

  img {
    object-fit: contain;
    border-radius: 50%;
  }

  div {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.black};
  }

  span {
    color: ${({ theme }) => theme.colors.black};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 0;
    cursor: default;
  }

  .notification-time {
    color: ${({ theme }) => theme.colors.secondary_80};
  }
`
