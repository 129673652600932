export const pagesPaths = {
  analytics: '/analytics',
  hirePeople: '/hired-people',
  verifyAccount: '/verification',
  dashboard: '/dashboard',
  newJob: '/jobs/new-job',
  companyProfile: '/company-profile',
  deleteProfile: '/delete-profile',
  jobs: '/jobs',
  headhunting: '/candidates',
  libraryResources: '/validate-library-resources',
  activeAccounts: '/active-accounts',
  login: '/login',
  register: '/sign-up',
  deleteAccountRequests: 'delete-account-requests',
  root: '/',
  candidateQuestsActivities: '/candidate-quests-activities',
  candidateQuestsActivity: (id: string, month: number, year: number) =>
    `/candidate-quests-activities/${id}?month=${month}&year=${year}`,
  candidatePublicView: (id: string) => `/candidates/${id}/view`,
  quiz: (id: string, quizName: string) => `/quizzes/${id}?quizName=${quizName}`,
  quizCategory: (quizId: string, categoryId: string) => `/quizzes/${quizId}/category/${categoryId}`,
  candidate: (id: string) => `/candidates/${id}`,
  jobPublicView: (slug: string) => `/jobs/${slug}/view`,
  communityWikiItem: (slug: string) => `/community/wiki/${slug}`,
}
