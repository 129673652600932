import { http, httpPublic } from '../http'
import { IRegisterUser, IResetPassword, IUser } from './auth.types'

export const ApiPathsUsers = {
  users: 'users',
  user: 'auth/user',
  login: 'auth/login',
  register: 'auth/register/recruiter',
  verification: `auth/validations/email`,
  verificationResend: `auth/validations/email/code`,
  passwordSend: 'auth/forgot-password',
  passwordReset: `auth/set-new-password`,
  tokenRefresh: `auth/token-refresh`,
  changeEmail: `auth/change-email`,
}

export const verifyUser = async (url: string, { arg }: { arg: { email: string; code: number } }) => {
  const { data } = await http.patch(url, arg)
  return data
}

export const resendCode = async (url: string, { arg }: { arg: { email: string } }) => {
  await http.put(url, arg)
}

export const login = async (url: string, { arg }: { arg: { email: string; password: string } }): Promise<IUser> => {
  const { data } = await httpPublic.post(url, arg)
  return data
}

export const register = async (url: string, { arg }: { arg: IRegisterUser }): Promise<IUser> => {
  const { data } = await http.post(url, arg)
  return data
}

export const sendPassword = async (url: string, { arg }: { arg: { email: string } }): Promise<IResetPassword> => {
  const { data } = await http.put(url, arg)
  return data
}

export const resetPassword = async (
  url: string,
  { arg }: { arg: { email: string; code: number; password: string } },
) => {
  const { data } = await http.patch(url, arg)
  return data
}
