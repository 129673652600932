export enum pagesPathsEnum {
  verifyAccount = '/verification',
  dashboard = '/dashboard',
  newJob = '/jobs/new-job',
  companyProfile = '/company-profile',
  deleteProfile = '/delete-profile',
  jobs = '/jobs',
  headhunting = '/candidates',
  validateCompanies = '/validate-companies',
  libraryResources = '/validate-library-resources',
  login = '/login',
  register = '/sign-up',
  deleteAccountRequests = 'delete-account-requests',
  root = '/',
  activeAccounts = '/active-accounts',
  communityJobs = '/community/jobs',
  whatsApp = '/whatsapp-channels',
  communityWiki = '/community/wiki',
  communityWikiItem = '/community/wiki/:slug',
  analytics = '/analytics',
  referral = '/referral',
}
