import { DetailedHTMLProps, HTMLAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const ProfileAvatar = ({ children }: IProps) => {
  return (
    <Div className="flex align-items-center justify-content-center w-2rem h-2rem border-circle border-1 border-300">
      {children}
    </Div>
  )
}

const Div = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`
