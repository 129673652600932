import { DetailedHTMLProps, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { LoadingAnimation } from './LoadingAnimation'

interface IProps extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'> {
  size?: number
  className?: string
  message?: string
  minHeight?: string
}

export const LoadingBox = ({ message, size, ...props }: IProps) => {
  return (
    <Div {...props}>
      <LoadingAnimation size={size} />

      {message && <p>{message}</p>}
    </Div>
  )
}

const Div = styled.div<{ minHeight?: string }>`
  position: absolute !important;
  inset: 0 0 0 0;
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  gap: 1em;
  background-color: inherit;
  z-index: 1;
  backdrop-filter: blur(1px);

  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `};
`
