import cx from 'classnames'
import { Button } from 'common/components/Button/Button'
import { LoadingAnimation } from 'common/components/States/Loading/LoadingAnimation'
import { handleError } from 'common/utils/validation/errorHandler'
import { PrimeIcons } from 'primereact/api'
import { Sidebar } from 'primereact/sidebar'
import { ReactNode, useState } from 'react'
import Notification from 'services/notifications/components/Notification'
import {
  ApiPathsNotifications,
  useGetAllNotifications,
  useMarkAllNotificationsAsRead,
  useUpdateNotification,
} from 'services/notifications/notifications.service'
import { INotification } from 'services/notifications/notifications.type'
import styled from 'styled-components'
import { mutate } from 'swr'
interface INotificationsPanel {
  children: ReactNode
}

const NotificationsPanel = ({ children }: INotificationsPanel) => {
  const [visible, setVisible] = useState(false)
  const [filter, setFilter] = useState<boolean>(true)

  const { data, isLoading } = useGetAllNotifications(filter)

  const { trigger: triggerPatchNotification } = useUpdateNotification({
    onError(error) {
      handleError(error)
    },
    onSuccess: async () => {
      await mutate(ApiPathsNotifications.notifications)
    },
  })

  const { trigger: triggerMarkAllNotification } = useMarkAllNotificationsAsRead({
    onError(error) {
      handleError(error)
    },
    onSuccess: async () => {
      await mutate(ApiPathsNotifications.notifications)
    },
  })

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setVisible(true)
  }

  const sidebarHeader = (
    <Header>
      <h1>Notifications</h1>
    </Header>
  )

  return (
    <>
      <span onClick={handleClick} className="cursor-pointer">
        {children}
      </span>
      <StyledSidebar icons={sidebarHeader} visible={visible} position="right" onHide={() => setVisible(false)}>
        <div className="flex justify-content-between align-items-center">
          <div className="flex gap-3">
            <span onClick={() => setFilter(true)} className={cx({ active: filter === true })}>
              All
            </span>
            <span onClick={() => setFilter(false)} className={cx({ active: filter === false })}>
              Unread
            </span>
          </div>
          <Button onClick={triggerMarkAllNotification} variant="ghost">
            Mark all as read <i className={PrimeIcons.CHECK_CIRCLE} />
          </Button>
        </div>
        <div className="py-3 px-2">
          {isLoading ? (
            <LoadingAnimation />
          ) : (
            data?.data?.map((notification: INotification, index: number) => (
              <Notification
                key={index}
                notification={notification}
                patchNotification={triggerPatchNotification}
                onHideSidebar={() => setVisible(false)}
              />
            ))
          )}
        </div>
      </StyledSidebar>
    </>
  )
}

const Header = styled.header`
  h1 {
    font-weight: 600;
    font-size: 22px;
    line-height: 140%;
    color: ${({ theme }) => theme.colors.black};
  }
`

const StyledSidebar = styled(Sidebar)`
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    height: 18px;
    color: ${({ theme }) => theme.colors.gray_85};
    padding: 0 0.5rem;
    border-radius: 8px;
    cursor: pointer;
  }
  .active {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.black};
  }
`

export default NotificationsPanel
