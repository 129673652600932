import BellIcon from 'assets/icons/BellIcon.svg'
import NotificationsPanel from 'services/notifications/components/NotificationsPanel'
import styled from 'styled-components'
import { Breadcrumbs } from './Breadcrumbs'

export const Nav = () => {
  return (
    <StyledNav className="px-4 py-3">
      <nav className="flex justify-content-between align-items-center">
        <Breadcrumbs />

        <div className="flex align-items-center gap-3">
          {/* <InfoTooltip position="bottom">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText placeholder="Search" disabled />
            </span>
          </InfoTooltip> */}

          <NotificationsPanel>
            <BellIcon />
          </NotificationsPanel>
        </div>
      </nav>
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid #ecedee;
  color: ${({ theme }) => theme.colors.black};
  height: ${({ theme }) => theme.constants.navbarHeight};
`
