import { GoogleTagManager } from '@next/third-parties/google'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import { Page } from 'page'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Theme } from 'styles/Theme'
import { Layout } from '../common/components/Layout/Layout'

type Props = AppProps & {
  Component: Page
}

function MyApp({ Component, pageProps }: Props) {
  const getLayout = Component.getLayout

  const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'production'
  const addScripts = getLayout && isProd
  return (
    <>
      <Theme>
        <GoogleTagManager gtmId="GTM-N2GN8HS3" />
        <ToastContainer hideProgressBar theme="dark" pauseOnHover />
        {addScripts && (
          <>
            <Script src="https://cdn-cookieyes.com/client_data/2b3b80797eeba3d280dbd91e/script.js" strategy="worker" />
          </>
        )}
        {getLayout ? (
          getLayout(<Component {...pageProps}></Component>)
        ) : (
          <Layout {...pageProps}>
            <Component {...pageProps}></Component>
          </Layout>
        )}
      </Theme>
    </>
  )
}

export default appWithTranslation(MyApp)
