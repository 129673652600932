import BriefcaseIconSrc from 'assets/icons/Briefcase.svg'
import ChatCircleDots from 'assets/icons/ChatCircleDots.svg'
import FolderOpen from 'assets/icons/FolderOpen.svg'
import HouseLine from 'assets/icons/HouseLine.svg'
import UserFocus from 'assets/icons/UserFocus.svg'
import LogoSrc from 'assets/logo.svg'
import { Button } from 'common/components/Button/Button'
import { NavLink } from 'common/components/Layout/NavLink'
import { BasicModal } from 'common/components/Modals/BasicModal'
import { pagesPathsEnum } from 'common/router/router.enum'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useGetCompany } from 'services/companies/company.service'
import { CompanyMenu } from 'services/companies/components/CompanyMenu'
import styled from 'styled-components'

export const CompanySidebar = () => {
  const { company } = useGetCompany()

  const router = useRouter()
  // const { data: companyProfileCompletness, isLoading } = useCompanyProfileCompletness()
  const [isVisible, setIsVisible] = useState(false)
  const [redirectLink, setRedirectLink] = useState('')

  const displayNavLink = (text: JSX.Element, href: string) => {
    return router.pathname.includes('/jobs/new-job') ? (
      <Button
        onClick={() => {
          setIsVisible(!isVisible)
          setRedirectLink(href)
        }}
        variant="ghost"
        size="large"
        className="asside-button"
      >
        {text}
      </Button>
    ) : (
      <NavLink href={href}>{text}</NavLink>
    )
  }

  return (
    <Aside className="flex flex-column align-items-center h-screen">
      <NavLink href="/" className="px-3 py-5">
        <LogoSrc className="logo" width="180" height="13.61" />
      </NavLink>

      <ul className="flex flex-column overflow-auto flex-grow-1 w-full px-3 gap-1">
        <li>
          {displayNavLink(
            <>
              <HouseLine /> Dashboard
            </>,
            '/dashboard',
          )}
        </li>
        <li>
          {displayNavLink(
            <>
              <BriefcaseIconSrc />
              Company Profile
            </>,
            '/company-profile',
          )}
        </li>
        <li>
          {displayNavLink(
            <>
              <FolderOpen /> Jobs Listing
            </>,
            '/jobs',
          )}
        </li>

        <li>
          {displayNavLink(
            <>
              <UserFocus /> Headhunting
            </>,
            pagesPathsEnum.headhunting,
          )}
        </li>
        <li>
          <NavLink href="https://forms.office.com/e/7DBwkyaJkK" passHref legacyBehavior>
            <a target="_blank">
              <ChatCircleDots /> Give Feedback
            </a>
          </NavLink>
        </li>
        {/* {!isCompanyNotApproved && (
          <li>
            <NavLink href="/calendar">
              <CalendarBlank /> Calendar
            </NavLink>
          </li>
        )} */}
      </ul>
      {/* {!isLoading && companyProfileCompletness && ( */}
      <div className="py-4 flex flex-column gap-4 w-full px-3">
        {/* <CompanyUpgradeProWidget /> */}

        {/* {companyProfileCompletness.is_complete ? (
            <CompanyUpgradeProWidget />
          ) : (
            <CompanyCompletnessWidget
              actionPoints={companyProfileCompletness.action_points}
              isCompanyNotApproved={isCompanyNotApproved}
            />
          )} */}
        <CompanyMenu />
      </div>
      {/* )} */}
      <BasicModal title="Are you sure you want to leave this page?" defaultVisible={isVisible}>
        <div className="flex flex-column">
          <div>
            By clicking &quot;Confirm,&quot; you will be redirected to a different page and any unsaved changes will be
            lost. To stay on the current page, click &quot;Cancel.&quot;
          </div>
          <div className="flex justify-content-around mt-3">
            <Button size="large" variant="ghost" onClick={() => setIsVisible(false)}>
              Cancel
            </Button>
            <Button
              size="large"
              variant="primary"
              onClick={() => {
                router.push(redirectLink)
                setIsVisible(false)
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </BasicModal>
    </Aside>
  )
}

const Aside = styled.aside`
  background-color: ${({ theme }) => theme.colors.white};
  border-right: 1px solid #ecedee;

  .asside-button {
    font-size: 14px;
    font-weight: normal;
    padding: 14px;
  }

  .disabled {
    color: ${({ theme }) => theme.colors.gray_55};
  }

  ul {
    list-style: none;
  }

  > ul > li {
    a {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      padding: 0.875rem;

      &.active {
        border-radius: 8px;
        background-color: ${({ theme }) => theme.colors.primary_100};
      }
      &.disabled {
        opacity: 0.5;
      }

      &:hover:not(.active, .disabled) {
        border-radius: 8px;
        background-color: ${({ theme }) => theme.colors.gray_10};
      }
    }
  }
`
