import { FC } from 'react'
import { NotificationMessage } from 'services/notifications/components/NotificationMessage'
import styled from 'styled-components'
import { SWRMutationConfiguration } from 'swr/mutation'
import { INotification } from '../notifications.type'

interface INotificationComponent {
  notification: INotification
  onHideSidebar: () => void
  patchNotification: <SWRData = any>(
    extraArgument: any,
    options?: SWRMutationConfiguration<any, any, any, string, SWRData> | undefined,
  ) => Promise<any>
}

const Notification: FC<INotificationComponent> = ({ notification, patchNotification, onHideSidebar }) => {
  const viewNotification = (notification: INotification) => {
    patchNotification(notification.id)
  }

  return (
    <Div>
      <div className="m-3 flex gap-3">
        <div className="cursor-pointer" onClick={() => viewNotification(notification)}>
          <NotificationMessage notification={notification} onHideSidebar={onHideSidebar} />
        </div>
      </div>
      <Divider />
      {!notification.isSeen && <UnreadPill />}
    </Div>
  )
}

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray_40};
`

const Div = styled.div`
  position: relative;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.black};
  }
`
const UnreadPill = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;
  background-color: ${({ theme }) => theme.colors.error};
  width: 11px;
  height: 11px;
  border-radius: 11px;
`
export default Notification
