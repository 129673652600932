import { Poppins } from 'next/font/google'
import * as styled from 'styled-components'

export const poppins = Poppins({
  weight: ['200', '300', '400', '500', '600', '700'],
  style: ['italic', 'normal'],
  subsets: ['latin'],
})

export const GlobalStyles = styled.createGlobalStyle`
  html,
  * {
    font-family: ${poppins.style.fontFamily};
  }

  html,
  body {
    padding: 0;
    margin: 0 auto;
    font-family: ${poppins.style.fontFamily};
    font-style: normal;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    height: 100%;
  }

  #__next {
    height: 100%;
  }

  .primary-font {
    font-family: ${poppins.style.fontFamily};
  }

  .p-multiselect .p-multiselect-panel {
    z-index: 1010 !important;
  }

  button,
  .p-accordion-tab,
  .p-accordion,
  .p-accordion-header-text,
  .content {
    font-family: ${poppins.style.fontFamily};
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    border: none;
    outline: none;
  }

  img {
    object-fit: cover;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.secondary_70};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.secondary_100};
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .p-dialog .p-dialog-header-icons {
    top: 0.75rem;
    right: 0.75rem;
  }

  .CookieDeclaration {
    padding: 2rem;
  }

  .active-scroll-spy {
    background-color: ${({ theme }) => theme.colors.primary_100};
    border-radius: 15px;
  }

  .overflow-ellipses {
    width: calc(99%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .break-word {
    word-break: break-word;
  }

  .link {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary_blue};
    word-wrap: break-word;
    word-break: break-word;
  }
`
