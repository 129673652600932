import Files from 'assets/icons/Files.svg'
import SignOutIcon from 'assets/icons/SignOutIcon.svg'
import LogoSrc from 'assets/logo.svg'
import { pagesPathsEnum } from 'common/router/router.enum'
import { pagesPaths } from 'common/router/routes.utils'
import { useRouter } from 'next/router'
import { PrimeIcons } from 'primereact/api'
import { auth } from 'services/auth/auth.singleton'
import { useSWRConfig } from 'swr'
import { NavLink } from './NavLink'

export const ValidatorSidebar = () => {
  const router = useRouter()
  const { mutate } = useSWRConfig()

  const onLogout = async () => {
    await auth.clientLogout()
    mutate(
      () => true, // which cache keys are updated
      undefined, // update cache data to `undefined`
      { revalidate: false }, // do not revalidate
    )
    router.push(pagesPathsEnum.login)
  }
  return (
    <>
      <NavLink href="/" className="px-3 py-5">
        <LogoSrc className="logo" width="180" height="13.61" />
      </NavLink>

      <ul className="flex flex-column overflow-auto flex-grow-1 w-full px-3 gap-1">
        <li>
          <NavLink href="/analytics">
            <i className={`${PrimeIcons.CHART_PIE} ml-1`} />
            Analytics
          </NavLink>
        </li>
        <li>
          <NavLink href="/active-accounts">
            <i className={`${PrimeIcons.USERS} ml-1`} />
            Active accounts
            <i className={`${PrimeIcons.QUESTION_CIRCLE} ml-1`} />
          </NavLink>
        </li>
        <li>
          <NavLink href="/validate-companies">
            <i className={`${PrimeIcons.BUILDING} ml-1`} /> Validate Companies{' '}
            <i className={`${PrimeIcons.TIMES_CIRCLE} ml-1`} />
          </NavLink>
        </li>
        <li>
          <NavLink href={`/${pagesPaths.deleteAccountRequests}`}>
            <i className={`${PrimeIcons.USER_MINUS} ml-1`} /> Delete account requests
            <i className={`${PrimeIcons.QUESTION_CIRCLE} ml-1`} />
          </NavLink>
        </li>
        <li>
          <NavLink href="/quizzes">
            <i className={`${PrimeIcons.CHECK_SQUARE} ml-1`} /> Quizzes
            <i className={`${PrimeIcons.QUESTION_CIRCLE} ml-1`} />
          </NavLink>
        </li>
        <li>
          <NavLink href="/validations">
            <i className={`${PrimeIcons.BOOK} ml-1`} /> Validate Resources
          </NavLink>
        </li>
        <li>
          <NavLink href="/documents">
            <Files /> Documents <i className={`${PrimeIcons.TIMES_CIRCLE} ml-1`} />
          </NavLink>
        </li>
        <li>
          <NavLink href="/filters">
            <Files /> Filters <i className={`${PrimeIcons.TIMES_CIRCLE} ml-1`} />
          </NavLink>
        </li>
        <li>
          <NavLink href="/rewards">
            <i className={`${PrimeIcons.DOLLAR} ml-1`} /> Rewards
          </NavLink>
        </li>
        <li>
          <NavLink href="/feedbacks">
            <i className={`${PrimeIcons.USERS} ml-1`} /> Feedbacks
          </NavLink>
        </li>
        <li>
          <NavLink href="/inform-candidates">
            <i className={`${PrimeIcons.BELL} ml-1`} /> Inform Candidates
          </NavLink>
        </li>
        <li>
          <NavLink href="/candidate-quests-activities">
            <i className={`${PrimeIcons.FILE} ml-1`} />
            Quests Activities
          </NavLink>
        </li>
      </ul>
      <div className="py-4 flex flex-column gap-4">
        <span className="cursor-pointer flex align-items-center gap-2" onClick={onLogout}>
          <SignOutIcon /> Sign Out
        </span>
      </div>
    </>
  )
}
