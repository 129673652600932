export enum jobContractTypeEnum {
  permanent = 0,
  temporary,
  perVoyage,
}

export enum jobHiringLocationTypeEnum {
  worldwide = 0,
  acceptedNationalities,
  eu,
}

export enum jobStatusEnum {
  draft = 0,
  active,
  closed,
  closedWithoutHire,
  paused,
  underReview,
  suspended,
  archived,
  expired,
}

export const currencyAbbreviationToSymbol = {
  USD: '$',
  EUR: '€',
  GBP: '£',
}

export enum jobApplicationStatusEnum {
  applied = 'applied',
  inquiry = 'inquiry',
  rejectedByCandidate = 'rejectedByCandidate',
  rejectedByRecruiter = 'rejectedByRecruiter',
  cancelled = 'cancelled',
  decision = 'decision',
  hired = 'hired',
  accept = 'accept',
  toBeContacted = 'toBeContacted',
  scheduled = 'scheduled',
  firstInterview = 'firstInterview',
  rejectedAfterInterview = 'rejectedAfterInterview',
  clientSubmission = 'clientSubmission',
  clientInterview = 'clientInterview',
  offered = 'offered',
  deniedOffer = 'deniedOffer',
  standBy = 'standBy',
}

export enum jobApplicationStatusToLabelEnum {
  applied = 'Applied',
  inquiry = 'Pending candidate response',
  rejectedByCandidate = 'Rejected by candidate',
  rejectedByRecruiter = 'Rejected by us',
  cancelled = 'Cancelled',
  decision = 'Pending our decision',
  hired = 'Hired',
  accept = 'Offer accepted by candidate',
  toBeContacted = 'toBeContacted',
  scheduled = 'scheduled',
  firstInterview = 'firstInterview',
  rejectedAfterInterview = 'rejectedAfterInterview',
  clientSubmission = 'clientSubmission',
  clientInterview = 'clientInterview',
  offered = 'offered',
  deniedOffer = 'deniedOffer',
  standBy = 'standBy',
}
