import { IAPIKey } from 'services/companies/company.types'
import { http } from 'services/http'
import { ApiPathsJobs } from 'services/jobs/jobs.service'

export const sendJobOffer = async (url: string, { arg }: any) => {
  const { data } = await http.post(ApiPathsJobs.jobOffer(arg.job), {
    message: arg.message,
    candidateIds: arg.candidateIds,
  })

  return data
}

export const getOrCreateJobContent = async (
  url: string,
  { arg }: { arg: { position: string; jobCategory?: string } },
) => {
  const { data } = await http.post(url, {
    type: 'jobFields',
    position: arg.position,
    positionCategory: arg.jobCategory,
  })

  return data
}

export const sendApplicationUpdate = async (url: string, { arg }: any) => {
  const { data } = await http.patch(ApiPathsJobs.applicationStatus(arg.applicationId), {
    status: arg.message,
  })

  return data
}

export const getAPIKey = async (url: string) => {
  const { data } = await http.get(url)

  return data as IAPIKey
}

export const generateAPIKey = async (url: string) => {
  const { data } = await http.patch(url)

  return data
}

export const deleteAPIKey = async (url: string) => {
  const { data } = await http.delete(url)

  return data
}
