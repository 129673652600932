export enum userAccountStatusEnum {
  pendingVerification = 0,
  active,
  pendingSetNewPassword,
}

export enum userCategoryEnum {
  ADMIN,
  CANDIDATE,
  RECRUITER,
  VALIDATOR,
}
