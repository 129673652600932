import cx from 'classnames'
import styled, { css } from 'styled-components'
import { LoadingAnimation } from '../States/Loading/LoadingAnimation'

interface IStyleProps {
  variant?: 'primary' | 'ghost' | 'outline' | 'shadow' | 'greyShadow' | 'black' | 'white' | 'blue'
  size?: 'medium' | 'large'
  isLoading?: boolean
  iconOnly?: boolean
  width?: string
}

interface IProps extends IStyleProps, React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const Button = ({
  variant = 'primary',
  size = 'medium',
  isLoading,
  children,
  className,
  width,
  ...props
}: IProps) => {
  return (
    <StyledButton
      variant={variant}
      size={size}
      isLoading={isLoading}
      className={cx(className, { loading: isLoading })}
      {...props}
    >
      {isLoading && <LoadingAnimation size={{ medium: 16, large: 18 }[size]} />} {children}
    </StyledButton>
  )
}

const StyledButton = styled.button<IStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: ${({ theme }) => theme.colors.black};
  transition: background-color 300ms ease-out;
  cursor: pointer;
  border-radius: 8px;
  white-space: nowrap;

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ size }) =>
    ({
      medium: 'font-weight: 400; font-size: 14px; line-height: 20px; padding: 4px 8px;',
      large: 'font-weight: 600; font-size: 18px; line-height: 28px; padding: 8px 16px; gap: 8px;',
    }[size!])};

  ${({ iconOnly }) =>
    iconOnly &&
    css`
      > .pi {
        font-size: 1.5rem;
      }

      > svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    `};

  ${({ variant, theme }) =>
    ({
      primary: `background-color: ${theme.colors.primary_100}`,
      shadow: `background-color: ${theme.colors.primary_25}`,
      greyShadow: `background-color: ${theme.colors.secondary_25}`,
      ghost: `background-color: transparent;`,
      outline: `background-color: transparent; outline: 1px solid ${theme.colors.primary_100};`,
      black: `background-color: ${theme.colors.black}; outline: none;  color: ${theme.colors.gray_10}`,
      white: `background-color: ${theme.colors.white}; outline: none;  color: ${theme.colors.black}; border: 1px solid #A6A6A6;`,
      blue: `background-color: ${theme.colors.secondary_blue}; outline: none; color: ${theme.colors.white}`,
    }[variant!])};

  &:hover {
    ${({ variant, theme }) =>
      ({
        primary: `background-color: ${theme.colors.primary_55};`,
        shadow: `background-color: ${theme.colors.primary_10}`,
        greyShadow: `background-color: ${theme.colors.primary_100}`,
        ghost: `background-color: ${theme.colors.primary_100}; `,
        outline: `background-color: ${theme.colors.primary_10}; outline: 3px solid ${theme.colors.primary_100};`,
        black: `background-color: ${theme.colors.grey_100}; outline: none;`,
        white: `background-color: ${theme.colors.gray_10}; outline: none;`,
        blue: `background-color: ${theme.colors.secondary_blue_light}; outline: none; color: ${theme.colors.white}`,
      }[variant!])};
  }

  &:disabled,
  &.loading {
    pointer-events: none;
    ${({ variant, theme }) =>
      ({
        greyShadow: `background-color: ${theme.colors.gray_25}; color: ${theme.colors.gray_55};`,
        primary: `background-color: ${theme.colors.gray_25}; color: ${theme.colors.gray_55};`,
        shadow: `background-color: ${theme.colors.gray_25}; color: ${theme.colors.gray_55};`,
        ghost: `color: ${theme.colors.gray_55}; `,
        outline: `color: ${theme.colors.gray_55}; outline: 1px solid ${theme.colors.gray_40};`,
        black: `color: ${theme.colors.gray_10}`,
        white: `color: ${theme.colors.black}`,
        blue: `background-color: ${theme.colors.gray_25}; color: ${theme.colors.gray_55};`,
      }[variant!])};
  }
`
