export const Validations = {
  isEmail: (value: string) => {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
  },
  minLenght: (value: string) => {
    return value.trim().length >= 8
  },
  isRequired: (value: string) => {
    return value.trim().length > 0
  },
  maxLenght: (value: string) => {
    return value.trim().length < 50
  },
  oneUppercase: (value: string) => {
    return /(.*[A-Z].*)/.test(value)
  },
  oneLowercase: (value: string) => {
    return /(.*[a-z].*)/.test(value)
  },
  oneDigit: (value: string) => {
    return /(.*\d.*)/.test(value)
  },
  oneSpecialCharacter: (value: string) => {
    return /(.*[-+_!@#$%^&*.,?].*)/.test(value)
  },
}

export const isURL = /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:[a-z0-9-]+\.)*[a-z0-9-]+\.[a-z]+(?:[/?#]\S*)?$/
export const validName = /^[a-zA-Z ]+$/
export const validCompanyName = /^[a-zA-Z0-9.\s]+$/
export const validNote = /\b\w+[^\w\s]?\s?[\w\s]*\b/

export function isUUID(str?: string) {
  if (!str) return false
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  return uuidRegex.test(str)
}
