export const jobsErrors = {
  title: 'Job Title',
  jobCategory: 'Job Category',
  position: 'Job Position',
  description: 'Job Description',
  requiredDocuments: 'Required documents',
  hiringLocation: 'Accepted nationalities',
  contractType: 'Contract Type',
  signOnDate: 'Sign on',
  vessel_details: 'Vessel Details',
  salary: 'Salary',
  min_requirements: 'Min. requirements',
  hiringMultiple: 'Hiring multiple candidates',
  vessel: 'Vessel',
  vesselName: 'Vessel name',
  teu: 'TEU (For container vessels only)',
  vesselEngineType: 'Main engine',
  bhp: 'BHP',
  kw: 'kW',
  vesselFlag: 'Vessel flag',
  vesselYearBuild: 'Year build',
  gt: 'GT',
  dwt: 'DWT',
  salaryCurrency: 'Currency',
  salaryFrom: 'From',
  salaryTo: 'To',
}

export const translateLabel = (label: keyof typeof jobsErrors) => jobsErrors?.[label]

export const errorParent = {
  teu: 'vessel_details.',
  vesselEngineType: 'vessel_details.',
  bhp: 'vessel_details.',
  kw: 'vessel_details.',
  vesselFlag: 'vessel_details.',
  vesselYearBuild: 'vessel_details.',
  gt: 'vessel_details.',
  dwt: 'vessel_details.',
  salaryCurrency: 'salary',
  salaryFrom: 'salary',
  salaryTo: 'salary',
}

export const getErrorKeyParent = (errKey: keyof typeof errorParent) => errorParent?.[errKey]
