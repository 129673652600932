import Sidebar from 'common/components/Layout/Sidebar'
import { throttle } from 'lodash'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/saga-orange/theme.css'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BasicModal } from '../Modals/BasicModal'
import { Nav } from './Nav'

interface IProps {
  children: React.ReactNode | React.ReactNode[]
}

export const Layout = ({ children }: IProps) => {
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    const throttledHandleResize = throttle(function () {
      if (window.innerWidth <= 768) {
        setShowPopup(true)
      } else {
        setShowPopup(false)
      }
    }, 5000)

    window.addEventListener('resize', throttledHandleResize)

    throttledHandleResize()

    return () => {
      window.removeEventListener('resize', throttledHandleResize)
    }
  }, [])

  return (
    <Div>
      <Sidebar />
      <div className="pageFeatures">
        <Nav />
        <main className="relative">{children}</main>
      </div>
      <BasicModal defaultVisible={showPopup} closable={false}>
        <div className="p-3">
          <h3 className="mb-3">Sorry, this feature cannot be accessed on mobile devices or small screens.</h3>
          <p>Please use a laptop or desktop computer to access this functionality. Thank you!</p>
        </div>
      </BasicModal>
    </Div>
  )
}

const Div = styled.div`
  display: grid;
  grid-template-columns: 212px 1fr;
  height: 100%;
  overflow: hidden;

  > div > main {
    height: calc(100vh - ${({ theme }) => theme.constants.navbarHeight});
    overflow: auto;
  }

  @media (max-width: 768px) {
    .pageFeatures {
      height: 100%;
      overflow: auto;
    }
  }
`
