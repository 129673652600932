import { jobsErrors, translateLabel } from 'common/utils/errors.utils'
import { toast } from 'react-toastify'

export const handleError = (error?: any) => {
  const message = error?.response?.data?.message || 'Server not responding'
  toast.error(message && Array.isArray(message) ? message[0] : message)
}

export const handleMessage = (message?: any) => {
  const error = message?.response?.data?.error || 'Server not responding'
  toast.error(error && Array.isArray(error) ? error[0] : error)
}

export const toastErrorMessage = (error: any) => {
  const errorToDisplay: string[] = []

  if (error?.response?.data?.error) {
    error.response.data.error.forEach((err: any) => {
      const errorKey = Object.keys(err)[0]
      errorToDisplay.push(err?.[errorKey].replace([errorKey], translateLabel(errorKey as keyof typeof jobsErrors)))
    })
  }

  toast.error(errorToDisplay.length && Array.isArray(errorToDisplay) ? errorToDisplay[0] : errorToDisplay)
}
