import * as styled from 'styled-components'
import { poppins } from './GlobalStyles.styled'

export const PrimereactThemeResets = styled.createGlobalStyle`
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: ${({ theme }) => theme.colors.primary_100};
    background: ${({ theme }) => theme.colors.primary_100};
  }

  .p-dropdown {
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray_85};
    border: 1px solid ${({ theme }) => theme.colors.gray_70};
    border-radius: 8px;
  }
  .p-multiselect {
    border: 1px solid ${({ theme }) => theme.colors.gray_70};
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray_85};
    border-radius: 8px;
    display: flex;
    align-items: center;
  }
  .p-dialog {
    border-radius: 30px;
  }
  .p-dialog .p-dialog-header {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  .p-dialog .p-dialog-content:last-of-type,
  .p-dialog-footer {
    border-bottom-right-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
  }
  .p-dialog-title {
    display: flex;
    justify-content: center;
  }
  .p-dialog-header-icons {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .p-tabview {
    .p-tabview-nav {
      border: 1px solid transparent;
      border-width: 0 0 2px 0;
    }
    .p-tabview-nav li .p-tabview-nav-link {
      border-color: transparent;
      padding: 0.5rem 0;
      :not(.p-disabled):focus {
        box-shadow: none;
      }
    }
    .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.black};
    }
    .p-tabview-panels {
      padding: 1rem 0;
    }
  }
  .p-tabview-nav {
    gap: 2rem;
  }

  .p-progressbar {
    display: none;
  }

  .p-fileupload .p-fileupload-row > div {
    padding: 0;
  }

  .p-radiobutton {
    .p-radiobutton-box {
      &.p-highlight {
        background-color: ${({ theme }) => theme.colors.primary_100};
      }

      .p-radiobutton-icon {
        background-color: ${({ theme }) => theme.colors.gray_85};
      }
    }
  }

  .p-datatable .p-datatable-thead > tr > th {
    background: none;
    color: #828282;
    font-size: 13px;
    font-weight: 400;
  }

  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #4f4f4f;
    font-size: 10px;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 8px 1rem;
  }

  .p-component-overlay {
    backdrop-filter: blur(10px);
  }

  .p-sidebar-header {
    justify-content: space-between;
  }

  .p-sidebar-right .p-sidebar {
    border-radius: 30px 0px 0px 30px;
  }

  .p-splitbutton.p-component {
    border-radius: 8px;

    > button {
      background-color: ${({ theme }) => theme.colors.primary_100};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      color: ${({ theme }) => theme.colors.black};
      transition: background-color 300ms ease-out;
      cursor: pointer;
    }

    > button:first-child {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      padding: 8px 16px;
      border-radius: 8px 0 0 8px;
    }

    > button:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  .dropdown-menu {
    font-family: ${poppins.style.fontFamily};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: ${({ theme }) => theme.colors.black};
    transition: background-color 300ms ease-out;
    cursor: pointer;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.primary_25};
    border: 0;
    outline: 0;

    .p-dropdown-label.p-placeholder {
      color: ${({ theme }) => theme.colors.black};
      font-family: ${poppins.style.fontFamily};
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      padding: 8px 16px;
    }
  }
`
